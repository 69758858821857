import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

// markup
const NotFoundPage = () => {
  return (
    <div className="bg-slate-700 h-screen text-white flex justify-center items-center">
      <Helmet>
        <title>Powerlines</title>
        <meta name='description' content='Poskytujeme inžinierske činnosti pre líniové stavby a v oblasti projekcie elektrických vedení VN a VVN' />
        <link rel='icon' href='/favicon.ico' />
      </Helmet>
      <main className="mx-auto p-5">
        <title>Not found</title>
        <h1 className="text-3xl">Stránka nenájdená</h1>
        <p className="text-lg">
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{" "}
          Nenašli sme čo ste hľadali.
          <br />
          <Link className="text-blue-400" to="/">Vrátiť sa</Link>
        </p>
      </main>
    </div>
  )
}

export default NotFoundPage
